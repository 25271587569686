@import '~modern-normalize/modern-normalize.css';
body {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: #1c658e;
}

.error-message {
  color: #f2545b;
}


.warning-message-block {
  color: whitesmoke !important;
  background-color: #ff9800 !important;
  border: #c67600 solid 1px;
  border-radius: 5px 5px;
  margin: 10px 10px;
  padding: 10px 10px;
}

.warning-message {
  color: #ff9800;
  background-color: whitesmoke !important;
}